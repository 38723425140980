import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { IntlProvider } from 'react-intl-hooks';

import './index.css';

async function prepareLanguageData() {
	try {
		const [en, zh] = await Promise.all([
			fetch(process.env.REACT_APP_API_END_POINT+'content/en.php').then((response) => response.json()),
			fetch(process.env.REACT_APP_API_END_POINT+'content/zh.php').then((response) => response.json()),
	  	]);
		const data = {
			zh: zh,
			en: en,
		};
		if(localStorage.getItem('lang') === null) localStorage.lang="en";
		ReactDOM.render(
			<IntlProvider
				locale={
					localStorage.getItem('lang') === null
						? navigator.language === 'zh-CN'
							? 'zh'
							: 'en'
						: localStorage.getItem('lang')
				}
				messages={
					data[
						localStorage.getItem('lang') === null
							? navigator.language === 'zh-CN'
								? 'zh'
								: 'en'
							: localStorage.getItem('lang')
					]
				}
				defaultLocale='en'
			>
				<App />
			</IntlProvider>,
			document.getElementById('root'),
		);
		
		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		serviceWorker.unregister();
		
	} catch (err) {
	  	console.log(err);
	}
}
prepareLanguageData();

